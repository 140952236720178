<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入关键字(名称、编号)"
        style="width: 300px"
        class="filter-item"
        clearable
      />

      <CollectorSelect
        :value="listQuery.collectorId"
        @valueFunc="getQueryValueFunc"
        type="collectorId"
        widthStyle="200px"
      />
      <Category
        :isRequired="true"
        :value="listQuery.category"
        @valueFunc="getQueryValueFunc"
        widthStyle="200px"
        type="category"
      />
      <ArtistsSelect
        :value="listQuery.artistsId"
        @valueFunc="getQueryValueFunc"
        type="artistsId"
        widthStyle="200px"
      />
      <el-select
        v-model="listQuery.years"
        placeholder="年代"
        style="width: 90px"
        class="filter-item"
        clearable
      >
        <el-option label="正常" :value="1" />
        <el-option label="冻结" :value="0" />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {}, 'CREATE')"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
    >
      <el-table-column prop="id" label="编号" width="80"> </el-table-column>
      <el-table-column prop="name" label="名字" width="100"> </el-table-column>
      <el-table-column prop="category" label="分类" width="100">
      </el-table-column>
      <el-table-column label="收藏家">
        <template slot-scope="scope">
          <span>{{ scope.row.collector.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="艺术家">
        <template slot-scope="scope">
          <span>{{ scope.row.artists.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="goods_years" label="年代"></el-table-column>
      <el-table-column prop="default_img" label="封面图" width="120px">
        <template slot-scope="scope">
          <el-image
            v-for="item in scope.row.default_img"
            :key="item"
            :src="item"
            style="width: 100px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column label="图集" width="200px">
        <template slot-scope="scope">
          <el-image
            v-for="item in scope.row.imgs"
            :key="item"
            :src="item"
            style="width: 70px"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column prop="dsize" label="大小"></el-table-column>
      <el-table-column prop="price" label="价格"></el-table-column>
      <el-table-column prop="introduction" label="简称"></el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="checkDialogFormVisible(true, scope.row, 'UPDATE')"
            >编辑</el-button
          >

          <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="这是一段内容确定删除吗？"
            @confirm="deleteArt(scope.row)"
          >
            <el-button
              slot="reference"
              size="mini"
              type="danger"
              class="delete_warp"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      title="新增"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="收藏家" prop="collectorId">
          <CollectorSelect
            :value="form.collectorId"
            @valueFunc="getValueFunc"
            type="collectorId"
          />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="艺术家" prop="artistsId">
          <ArtistsSelect
            :value="form.artistsId"
            @valueFunc="getValueFunc"
            type="artistsId"
          />
        </el-form-item>
        <el-form-item label="分类" prop="category">
          <Category
            :isRequired="true"
            :value="form.category"
            @valueFunc="getValueFunc"
            type="category"
          />
        </el-form-item>
        <el-form-item label="封面图" prop="defaultImg">
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.ARTGOODS"
            @valueFunc="getImgsValueFunc"
            paramsType="defaultImg"
            :model="form.defaultImg"
            :maxCount="1"
          />
        </el-form-item>
        <el-form-item label="藏品年代" prop="goodsYears">
          <el-input v-model="form.goodsYears" placeholder="请选择" />
        </el-form-item>
        <el-form-item label="图集" prop="imgs">
          <UploadList
            :typeProps="UPLOAD_IMG_TYPE.ARTGOODS"
            @valueFunc="getImgsValueFunc"
            paramsType="imgs"
            :model="form.imgs"
          />
        </el-form-item>
        <el-form-item label="尺寸大小" prop="dsize">
          <el-input v-model="form.dsize" placeholder="请选择" />
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input v-model="form.price" placeholder="请选择" />
        </el-form-item>

        <el-form-item label="简介" prop="introduction">
          <el-input
            placeholder="请选择"
            type="textarea"
            v-model="form.introduction"
            :autosize="{ minRows: 2, maxRows: 8 }"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false, {}, '')"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="saveAddOrEditData"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatApiDate, getFileName } from "@/utils/utils";
import { goodsGetList, goodsCreateOrUpdate, goodsDelete } from "@/api/art";
import Category from "@/views/components/category.vue";
import CollectorSelect from "@/views/components/collectorSelect.vue";
import ArtistsSelect from "@/views/components/artistsSelect.vue";
import UploadList from "@/views/components/uploadList.vue";
import { UPLOAD_IMG_TYPE } from "@/utils/enumUtile";

export default {
  computed: {
    formatApiDate() {
      return formatApiDate;
    },
    UPLOAD_IMG_TYPE() {
      return UPLOAD_IMG_TYPE;
    }
  },
  components: {
    Category,
    CollectorSelect,
    ArtistsSelect,
    UploadList
  },
  filters: {},
  data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        keyword: "",
        collector: "",
        artists: "",
        category: "", // 分类
        years: "", // 年份
        page: 1,
        limit: 10
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogType: "",
      rules: {
        artistsId: [
          { required: true, message: "艺术家不能为空", trigger: "change" }
        ],
        collectorId: [
          { required: true, message: "收藏家不能为空", trigger: "change" }
        ],
        name: [{ required: true, message: "名称不能为空", trigger: "change" }],
        category: [
          { required: true, message: "分类不能为空", trigger: "change" }
        ],
        defaultImg: [
          { required: true, message: "封面图不能为空", trigger: "change" }
        ]
      },
      form: {
        id: 0, // 用作与修改
        collectorId: "", // 收藏家
        name: "", // 姓名
        artistsId: "", // 艺术家id
        category: "", // 分类[":5:1:",":5:3:"]
        defaultImg: [], // 封面图
        goodsYears: "", // 藏品年代
        imgs: [], // 图集
        dsize: "", // 尺寸
        price: "", // 价格
        introduction: "" // 简介
      },
      btnLoading: false,
      artistsLoading: false, // 查询艺术家的搜索
      artistsOption: [] // 艺术家选项
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },

    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      goodsGetList(this.listQuery)
        .then(response => {
          const data = (response.data && response.data.data) || [];
          if (data && data.length !== 0) {
            data.map(item => {
              if (item.default_img) {
                const arr = [];
                arr.push(item.default_img);
                item.default_img = arr;
              }
              if (item.imgs) {
                item.imgs = item.imgs.split(",");
              }
            });
          }
          this.list = data;
          this.total = response.data.total || 0;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },

    /**
     * 切换状态
     * @param status
     * @param editObj
     * @param type
     */
    checkDialogFormVisible(status, editObj = {}, type) {
      if (status && type === "UPDATE") {
        this.form = {
          id: editObj.id, // 用作与修改
          goodsYears: editObj.goods_years,
          collectorId: editObj.collector_id,
          artistsId: editObj.artists_id,
          name: editObj.name, // 姓名
          category: editObj.category, // 分类[":5:1:",":5:3:"]
          dsize: editObj.dsize, // 尺寸
          price: editObj.price, // 价格
          introduction: editObj.introduction // 简介
        };

        if (editObj.default_img) {
          const defImgArr = [];
          const arr = editObj.default_img;
          arr.map(item => {
            defImgArr.push({
              url: item,
              name: getFileName(item)
            });
          });
          this.form.defaultImg = defImgArr;
        }
        if (editObj.imgs) {
          const attDataArr = [];
          const arr = editObj.imgs;
          arr.map(item => {
            attDataArr.push({
              url: item,
              name: getFileName(item)
            });
          });
          this.form.imgs = attDataArr;
        }
      }
      if (!status) {
        this.form = {
          id: 0, // 用作与修改
          collectorId: "", // 收藏家
          name: "", // 姓名
          artistsId: "", // 艺术家id
          category: "", // 分类[":5:1:",":5:3:"]
          defaultImg: [], // 封面图
          goodsYears: "", // 藏品年代
          imgs: [], // 图集
          dsize: "", // 尺寸
          price: "", // 价格
          introduction: "" // 简介
        };
        this.$refs.form.resetFields();
      }
      this.dialogType = type;
      this.dialogFormVisible = status;
    },
    /**
     * 确认添加还是编辑
     */
    saveAddOrEditData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const params = this.form;
          if (params.defaultImg && params.defaultImg.length !== 0) {
            const urlArr = params.defaultImg.map(obj => obj["url"]);
            params.defaultImg = urlArr.join(",");
          }
          if (params.imgs && params.imgs.length !== 0) {
            const urlArr = params.imgs.map(obj => obj["url"]);
            params.imgs = urlArr.join(",");
          }
          this.btnLoading = true;
          goodsCreateOrUpdate(params)
            .then(() => {
              this.getList();
              this.checkDialogFormVisible(false, {}, "");
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    },

    /**
     * 删除艺术品
     */
    deleteArt(scope) {
      if (!scope.id || scope.id === 0) {
        this.$notify({
          title: "提示",
          message: "获取数据异常",
          type: "warning",
          duration: 2000
        });
        return;
      }
      const params = {
        id: scope.id
      };
      goodsDelete(params).then(() => {
        this.getList();
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000
        });
      });
    },
    /**
     * 分类的值
     * @param type
     * @param result
     */
    getValueFunc(type, result) {
      if (type === "category") {
        this.form.category = JSON.stringify(result);
      }
      if (type === "collectorId") {
        this.form.collectorId = result;
      }
      if (type === "artistsId") {
        this.form.artistsId = result;
      }
    },

    /**
     * 查询条件分类的值
     * @param type
     * @param result
     */
    getQueryValueFunc(type, result) {
      if (type === "category") {
        this.listQuery.category = result.join("");
      }
      if (type === "collectorId") {
        this.listQuery.collector = result;
      }
      if (type === "artistsId") {
        this.listQuery.artists = result;
      }
    },
    /**
     *  获取图片的值
     * @param type
     * @param value
     */
    getImgsValueFunc(type, value) {
      if (type === "imgs") {
        this.form.imgs = value;
      }
      if (type === "defaultImg") {
        this.form.defaultImg = value;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.delete_warp {
  margin-left: 8px;
}
</style>
